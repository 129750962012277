import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/Header/Header"
import { menu } from "../mock"
import { Link } from "gatsby"
import Footer from "../components/Footer/Footer"


/**
 *
 * background-image: url(/static/players-2369e22….png);
 background-position: center 12%;
 background-repeat: no-repeat;
 background-color: transparent;
 background-size: 47%;
 mix-blend-mode: luminosity;
 width: 100%;
 *
 *
 * **/
const IndexPage = () => (
  <Layout>
    <SEO title="Conditions Générales d’Utilisation"/>

    <Header
      style={{
        maxHeight: "380px"
      }}
      withActivity={true}>
      <div className={"home-container"} style={{ marginTop: "4rem" }}>
        <div className={"home-title home-color"}>
          Conditions Générales <br/> d’Utilisation
        </div>

      </div>
    </Header>

    <div
      id={"function"}
      className={"page-container"}
      style={{}}
    >
      <div className={"page-content"}>


        <div className={"page-instruction page-cgu"}>
          <span style={{ fontSize: "1.8rem" }}>Conditions Générales d’Utilisation</span>
          <span style={{ fontSize: "1.2rem" }}>En vigueur au 01/01/2022</span>

          <p>
            Les présentes conditions générales d'utilisation (dites « CGU ») ont pour objet l'encadrement juridique des
            modalités de mise à disposition du site et des services par DEVAPP et de définir les conditions d’accès et
            d’utilisation des services par « l'Utilisateur ».
            Les présentes CGU sont accessibles sur le site à la rubrique «CGU».
          </p>

          <span style={{ paddingTop: "2rem", fontSize: "1.4rem" }}>Article 1 : Les mentions légales</span>

          <p>
            L’édition et la direction de la publication du site ineo360.fr est assurée par kaled mahajoub, domicilié 28
            rue du ballon.
          </p>
          <p>
            Numéro de téléphone est 06.09.12.38.7
          </p>
          <p>
            Adresse e-mail contact@ineo360.fr
          </p>
          <p>
            La personne est assujetie au RCS avec le numéro d'inscription Bobigny B 882962608 et son numéro de TVA
            intracommunautaire est le FR02882962608
          </p>

          <p>
            L'hébergeur du site ineo360.fr est la société Scaleway, dont le siège social est situé au 8 rue de la
            Ville-l’Evêque 75008 Paris, avec le numéro de téléphone : +33 1 84 13 00 00.
          </p>

          <span style={{ paddingTop: "2rem", fontSize: "1.4rem" }}>Article 2 : Accès au site</span>

          <p>
            Le site ineo360.fr permet à l'Utilisateur un accès gratuit aux services suivants :
          </p>
          <p>
            Le site internet propose les services suivants :
          </p>
          <p>
            système de réservation en ligne, invitation et recherche de partenaires de jeu
          </p>
          <p>

            Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les
            frais
            supportés par l'Utilisateur pour accéder au service (matériel informatique, logiciels, connexion Internet,
            etc.) sont à sa charge.
          </p>

          <span style={{ paddingTop: "2rem", fontSize: "1.4rem" }}>Article 3 : Collecte des données</span>


          <p>
            La Plateforme assure à l’Utilisateur une collecte et un traitement d’informations personnelles dans le
            respect de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative aux dispositions de la
            CNIL en France (Commission Nationale Informatiques et Libertés).
          </p>

          <p>
            En vertu des articles 39 et 40 de la loi en date du 6 janvier 1978, l’Utilisateur dispose d’un droit
            d’accès, de rectification, de suppression et d’opposition de ses données personnelles.
            Conformément au décret n°2007-451 du 25 mars 2007, le traitement de la demande peut être, selon sa nature,
            subordonné à la production préalable de la copie d’une pièce d’identité en cours de validité. Pour plus de
            détails, se référer aux Conditions Générales d’Utilisation.

          </p>
          <p>

            Les utilisateurs sont tenus de respecter les dispositions de la loi informatique et libertés mentionnée
            ci-dessus, dont la violation est passible de sanctions pénales. Ils doivent notamment s’abstenir, s’agissant
            des données à caractère personnel auxquelles ils pourraient avoir accès, de toute collecte, de toute
            utilisation détournée et, d’une manière générale, de tout acte susceptible de porter atteinte à la vie
            privée
            ou réputation des personnes.
          </p>


          <span style={{ paddingTop: "2rem", fontSize: "1.4rem" }}>Article 4 : Propriété intellectuelle</span>


          <p>
            Les marques, logos, signes ainsi que tous les contenus du site (textes, images, son…) font l'objet d'une
            protection par le Code de la propriété intellectuelle et plus particulièrement par le droit d'auteur.
          </p>

          <p>
            L'utilisateur doit solliciter l'autorisation préalable du site pour toute reproduction, publication, copie
            des différents contenus. Il s'engage à une utilisation des contenus du site dans un cadre strictement privé,
            toute utilisation à des fins commerciales et publicitaires est strictement interdite.
          </p>

          <p>
            Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans l’autorisation
            expresse de l’exploitant du site Internet constituerait une contrefaçon sanctionnée par l’article L 335-2 et
            suivants du Code de la propriété intellectuelle.
          </p>


          <p>
            Il est rappelé conformément à l’article L122-5 du Code de propriété intellectuelle que l’Utilisateur qui
            reproduit, copie ou publie le contenu protégé doit citer l’auteur et sa source.
          </p>

          <span style={{ paddingTop: "2rem", fontSize: "1.4rem" }}>Article 5 : Responsabilité</span>

          <p>Les sources des informations diffusées sur le site ineo360.fr sont réputées fiables mais le site ne
            garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions.</p>
          <p>
            Les informations communiquées sont présentées à titre indicatif et général sans valeur contractuelle. Malgré
            des mises à jour régulières, le site ineo360.fr ne peut être tenu responsable de la modification des
            dispositions administratives et juridiques survenant après la publication. De même, le site ne peut être
            tenue responsable de l’utilisation et de l’interprétation de l’information contenue dans ce site.
            Le site ineo360.fr ne peut être tenu pour responsable d’éventuels virus qui pourraient infecter l’ordinateur
            ou tout matériel informatique de l’Internaute, suite à une utilisation, à l’accès, ou au téléchargement
            provenant de ce site.

          </p>
          <p>
            La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et
            insurmontable d'un tiers.
          </p>

          <span style={{ paddingTop: "2rem", fontSize: "1.4rem" }}>Article 6 : Liens hypertextes</span>

          <p>
            Des liens hypertextes peuvent être présents sur le site. L’Utilisateur est informé qu’en cliquant sur ces
            liens, il sortira du site ineo360.fr. Ce dernier n’a pas de contrôle sur les pages web sur lesquelles
            aboutissent ces liens et ne saurait, en aucun cas, être responsable de leur contenu.
          </p>


          <span style={{ paddingTop: "2rem", fontSize: "1.4rem" }}>Article 7 : Cookies</span>
          <p>
            L’utilisateur est informé que lors de ses visites sur le site, un cookie peut s’installer automatiquement
            sur son logiciel de navigation.
          </p>
          <p>
            Les cookies sont de petits fichiers stockés temporairement sur le disque dur de l’ordinateur de
            l’Utilisateur par votre navigateur et qui sont nécessaires à l’utilisation du site ineo360.fr. Les cookies
            ne contiennent pas d’information personnelle et ne peuvent pas être utilisés pour identifier quelqu’un. Un
            cookie contient un identifiant unique, généré aléatoirement et donc anonyme. Certains cookies expirent à la
            fin de la visite de l’Utilisateur, d’autres restent.
          </p>
          <p>
            L’information contenue dans les cookies est utilisée pour améliorer le site ineo360.fr.
            En naviguant sur le site, L’Utilisateur les accepte.

          </p>
          <p>
            L’utilisateur doit toutefois donner son consentement quant à l’utilisation de certains cookies.
            A défaut d’acceptation, l’Utilisateur est informé que certaines fonctionnalités ou pages risquent de lui
            être refusées.

          </p>


          <p>
            L’utilisateur pourra désactiver ces cookies par l’intermédiaire des paramètres figurant au sein de son
            logiciel de navigation.
          </p>

          <span style={{ paddingTop: "2rem", fontSize: "1.4rem" }}>Article 8 : Droit applicable et juridiction compétente</span>
          <p>
            La législation française s'applique au présent contrat. En cas d'absence de résolution amiable d'un litige
            né entre les parties, les tribunaux français seront seuls compétents pour en connaître.
            Pour toute question relative à l’application des présentes CGU, vous pouvez joindre l’éditeur aux
            coordonnées inscrites à l’ARTICLE 1.

          </p>

        </div>


      </div>


    </div>

    <Footer>
      <div className={"home-menu"} style={{ alignItems: "center", justifyContent: "center" }}>
        {
          menu.map((it, i) => (
            <Link to={"#" + it.url} key={"small-index-menu-" + i}>
              <div
                className={"small-menu"}
                style={{ background: it.background }}
              >
                <img
                  src={require("../images/" + it.url + ".svg")}
                  alt={it.name}
                />
                <span>{it.name}</span>
              </div>
            </Link>
          ))
        };
      </div>
    </Footer>
  </Layout>
)

export default IndexPage
